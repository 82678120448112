import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstants } from './../config/app-constants';
import { HttpService } from './http.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userData$ = new BehaviorSubject<any>([]);

  constructor(
    private httpService: HttpService,
    private storageService: StorageService,
    private router: Router
  ) {}

  getUserData() {
    this.storageService.get(AppConstants.AUTH).then(res => {
      this.userData$.next(res);
    });
  }

  login(postData: any): Observable<any> {
    let formData = new FormData();
    formData.append('identity', postData.username);
    formData.append('password', postData.password);
    return this.httpService.post('login', formData);
  }

  signup(postData: any): Observable<any> {
    let formData = new FormData();
    formData.append('identity', postData.username);
    formData.append('password', postData.password);
    formData.append('email', postData.email);
    formData.append('name', postData.name);
    formData.append('mobile', postData.mobile);
    return this.httpService.post('register', formData);
  }

  logout() {
    this.storageService.clear();
    this.userData$.next('');
    this.router.navigate(['']);
  }
}
