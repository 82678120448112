import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { AuthService } from './../../services/auth.service';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {

  groups = [];
  public readonly serviceTypes = [
    'Swedish',
    'Deep Tissue',
    'Prenatal',
    'Sports',
  ];

  showLevel1 = null;
  showLevel2 = null;
  showLevel3 = null;
  
  public readonly durations = [60, 90, 120];

  public serviceTypeIsOpen: { [key: string]: boolean } = {
    Swedish: false,
    'Deep Tissue': false,
    Prenatal: false,
    Sports: false,
  };

  constructor(
    private platform: Platform,
    private auth: AuthService,
    //private splashScreen: SplashScreen,
    //private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      //this.statusBar.styleDefault();
      //this.splashScreen.hide();
    });
  }

  
  ngOnInit() {
    for (let i=0; i<10; i++) {
      this.groups[i] = {
        name: i,
        items: [],
        show: false
      };
      for (var j=0; j<3; j++) {
        this.groups[i].items.push(i + '-' + j);
      }
    }
    console.log('member', this.groups);
  }

  toggleGroup(group) {
    group.show = !group.show;
  };
  isGroupShown(group) {
    return group.show;
  };



  clearLevel() {
    this.showLevel1 = null;
    this.showLevel2 = null;
    this.showLevel3 = null;
  }

  toggleLevel1(idx: string) {
    if (this.isLevel1Shown(idx)) {
      this.showLevel1 = null;
    } else {
      this.showLevel1 = idx;
    }
  }

  isLevel1Shown(idx: string) {
    return this.showLevel1 === idx;
  }

  logoutAction() {
    this.auth.logout();
  }

}
