import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { StorageService } from '../services/storage.service';
import { AppConstants } from '../config/app-constants';
import { PersonService } from '../services/person.service';

@Injectable({
  providedIn: 'root'
})
export class MembersGuard implements CanActivate {
  constructor(
    public router: Router,
    public storageService: StorageService,
    private personService: PersonService,
    ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let router_id = Number(route.paramMap.get('id'));
      return new Promise(resolve => {
          this.storageService.get(AppConstants.AllowIds).then(allowIds_res => {
            if(allowIds_res == 'allow-all'){
              resolve(true);
            }else{
              this.personService.getMember(router_id).subscribe((person_res: any) => {
                let person = person_res.current_member;
              console.warn('router_id', router_id, 'person_res.spouse_id', person );
                if (allowIds_res.includes(router_id)) {
                  resolve(true);
                } else if(person._spouses.length > 0) { 
                  let allow_spouse = false;
                  person._spouses.forEach(element =>{
                    console.warn('spouse', element.id, element.name);
                    if (allowIds_res.includes(Number(element.id))) {
                        allow_spouse = true;
                      }
                  });
                  if(allow_spouse){
                    resolve(true);
                  }else{
                    this.router.navigate(['home/members/' + router_id]);
                    resolve(false);
                  }
                  
                }else{
                  this.router.navigate(['home/members/' + router_id]);
                  resolve(false);
                }
              });
            }
          })
          .catch(err => {
            resolve(false);
          });
      });
  }
  
}
